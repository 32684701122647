import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { MatDialogRef } from '@angular/material/dialog';

import { AuthService } from '../../../core/services/auth.service';
import { BreakpointService } from '../../../core/services/breakpoint.service';

import { AddDestinationDialogComponent } from '../../../modules/new-trip-planner/trip-planner-interaction/dialogs/add-destination-dialog/add-destination-dialog.component';

@Component({
  selector: 'app-customize-trip-dialog',
  templateUrl: './customize-this-trip-dialog.component.html',
  styleUrls: ['./customize-this-trip-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule],
})
export class CustomizeThisTripDialogComponent {
  unauthorizedParagraphs: string[] = [
    'Great! this trip will now be available in your planner for editing.',
    'In case you are already working on another trip it will replace it. You can always use the undo button.',
  ];

  authorizedParagraphs: string[] = [
    'You can find all your trips under “saved trips”',
  ];

  breakpointService: BreakpointService = inject(BreakpointService);
  authService: AuthService = inject(AuthService);
  #cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  #dialogRef: MatDialogRef<AddDestinationDialogComponent> = inject(
    MatDialogRef<AddDestinationDialogComponent>,
  );

  cancelDialog(): void {
    this.#dialogRef.close(false);
  }

  signUp(): void {
    this.authService.checkLogin(false, () => {
      this.#cdr.markForCheck();
    });
  }

  configDialog(): void {
    this.#dialogRef.close(true);
  }
}
