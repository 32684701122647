<div class="dialog">
    <div class="dialog__short-info">
        <div class="dialog__img-box">
            <span class="dialog__img-box__duration-range">
                <img ngSrc="assets/svg/o-calendar.svg" width="16" height="16" alt="calendar">
                <span>{{ trip.duration_range || trip.duration }} days</span>
            </span>
            <img [ngSrc]="trip.image_url" width="450" height="265" alt="trip image">
        </div>
        <p class="dialog__text-description">{{ trip.description }}</p>
        <button class="dialog__take-it" (click)="makeItMyTripPlanHandler()">
            Make it my plan
        </button>
    </div>
    <div class="dialog__content">
        <div class="dialog__trip-name">
            <p>Popular Trip: {{ trip.name }}</p>
            <button class="dialog__trip-name__close-btn" (click)="closeDialog()"></button>
        </div>
        <div class="dialog__trip-info">
            <div class="dialog__destinations">
                @if (trip.tripData; as tripData) {
                    <ul class="dialog__destinations-list">
                        @for (destination of tripData.preview; let idx = $index; track idx) {
                            <li class="dialog__destinations-list__item">
                                <div class="pin-box" [style.--pin-color]="PIN_COLORS[idx]">
                                    <span class="pin-box__number">{{ idx + 1 }}</span>
                                </div>
                                <p class="destination-name">{{ destination.name.split(',')[0] }}</p>
                                <span class="destination-divider"></span>
                                <p class="destination-days">{{ destination.name.split(',')[1] }}</p>
                                <span class="destination-divider grey"></span>
                                <p class="destination-days">{{ destination.days }}</p>
                            </li>
                        }
                    </ul>
                }
            </div>
            <div class="dialog__map">
                <app-preview-map
                        [savedTripView]="true"
                        [showMapControls]="false"
                        [destinationsRoute]="destinationsRoute"
                ></app-preview-map>
            </div>
        </div>
    </div>
</div>
