import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Inject,
  signal,
  WritableSignal,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgOptimizedImage } from '@angular/common';

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";

import { Trip } from '../../classes/trip';
import { PIN_COLORS } from 'src/app/shared/constants/map-pin.constant';
import { ITrip } from 'src/app/core/types/interfaces/popular-trip.interface';
import { IMapTripDestination } from 'src/app/core/types/interfaces/trip-elements.interface';

import { AuthService } from 'src/app/core/services/auth.service';
import { GoogleAnalyticService } from 'src/app/core/services/google-analytic.service';
import { TripWizardStateService } from '../../components/trip-wizard/trip-wizard-state.service';

import { PreviewMapComponent } from 'src/app/shared/maps/preview-map/preview-map.component';
import { CustomizeThisTripDialogComponent } from "../customize-this-trip-dialog/customize-this-trip-dialog.component";

@Component({
    selector: 'app-popular-trip-dialog',
    imports: [NgOptimizedImage, PreviewMapComponent],
    templateUrl: './popular-trip-dialog.component.html',
    styleUrl: './popular-trip-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopularTripDialogComponent {
  protected readonly PIN_COLORS = PIN_COLORS;
  protected readonly tripState: WritableSignal<Trip>;

  protected trip: ITrip;
  protected destinationsRoute: IMapTripDestination[] = [];

  #tripWizardStateService: TripWizardStateService = inject(
    TripWizardStateService,
  );
  #router: Router = inject(Router);
  #matDialog: MatDialog = inject(MatDialog);
  #authService: AuthService = inject(AuthService);
  #googleAnalyticService: GoogleAnalyticService = inject(GoogleAnalyticService);

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { trip: ITrip },
    private readonly dialogRef: MatDialogRef<PopularTripDialogComponent>,
  ) {
    this.trip = data.trip;
    this.setDestinationsRouteByTrip(data.trip);
    this.tripState = signal(this.#tripWizardStateService.currentTripStateValue);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  makeItMyTripPlanHandler(): void {
    const dialog = this.#matDialog.open(CustomizeThisTripDialogComponent);

    dialog.afterClosed().subscribe((isConfirm) => {
      if(!isConfirm) return;

      this.#tripWizardStateService.onNewCreateTrip$.next();
      const { tripStartDate } = this.tripState();
      this.submitNewPlan(tripStartDate);
      this.#googleAnalyticService.setUsePopularItineraryAnalytic({
        userID: this.#authService.userId,
      });
    });
  }

  private submitNewPlan(tripStartDate: string | Date): void {
    const {
      url,
      name,
      duration,
      description,
      tripData: { route },
    } = this.trip;
    let tripEndDate = new Date(tripStartDate);
    tripEndDate = new Date(
      tripEndDate.setDate(tripEndDate.getDate() + Number(duration)),
    );

    const firstDestination = route[0];
    const lastDestination = route[route.length - 1];
    const clearUrlName = url.split('trip-planner/')[1];

    this.tripState.update((trip) =>
      trip.updateTrip({
        name: '',
        savesId: clearUrlName,
        isOptimizedMode: false,
        startingLocationId: String(firstDestination.id),
        startingLabel: String(firstDestination.name),
        startingCountry: String(firstDestination.countryId),
        endingLocationId: String(lastDestination.id),
        endingLabel: String(lastDestination.name),
        endingCountry: String(lastDestination.countryId),
        tripEndDate: tripEndDate,
        numDays: Number(duration),
        route: route,
        plannerInfo: {
          type: 'popular',
          name: name,
          description: description,
        },
        includes: [],
        excludes: [],
      }),
    );

    this.#tripWizardStateService.currentTripState = new Trip({
      ...this.tripState(),
    });
    this.#tripWizardStateService.byUserInteraction = true;
    this.dialogRef.close();
    setTimeout(() => this.#router.navigateByUrl(url), 250);
  }

  private setDestinationsRouteByTrip(trip: ITrip): void {
    this.destinationsRoute = [];
    const {
      tripData: { route },
    } = trip;

    route.forEach((r: any): void => {
      const parsedCityCoordinate = r.coordinates
        .split(',')
        .map((cs: string) => parseFloat(cs.trim()))
        .reverse();
      this.destinationsRoute.push({
        coordinates: parsedCityCoordinate,
        nextLegIsFlight: false,
      });
    });
  }
}
