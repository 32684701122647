@if (breakpointService.isDesktop()) {
    <div class="dialog">
        <h2 class="dialog__title">Customize trip</h2>

        @for(paragraph of unauthorizedParagraphs; track paragraph) {
            <p class="dialog__paragraph">{{paragraph}}</p>
        }

        @if (!authService.userId) {
            <button class="dialog__sign-in" (click)="signUp()">Sign up to save your trips</button>
        } @else {
            @for(paragraph of authorizedParagraphs; track paragraph) {
                <p class="dialog__paragraph">{{paragraph}}</p>
            }
        }

        <div class="dialog__actions">
            <button class="dialog__button dialog__button--cancel" (click)="cancelDialog()">
                Cancel
            </button>
            <button class="dialog__button dialog__button--add" (click)="configDialog()">
                Ok
            </button>
        </div>
    </div>
} @else {
    <div class="mobile-dialog occasion-genius-dialog">
        <div class="mobile-dialog__header">
            <h2 class="mobile-dialog__header--title">
                Customize trip
            </h2>
        </div>
        <div class="mobile-dialog__content">
            @for(paragraph of unauthorizedParagraphs; track paragraph) {
                <p class="mobile-dialog__paragraph">{{paragraph}}</p>
            }

            @if(!authService.userId) {
                <button class="mobile-dialog__sign-in" (click)="signUp()">Sign up to save your trips</button>
            } @else {
                @for(paragraph of authorizedParagraphs; track paragraph) {
                    <p class="mobile-dialog__paragraph">{{paragraph}}</p>
                }
            }
        </div>
        <div class="mobile-dialog__footer">
            <button class="btn-default btn-gray" (click)="cancelDialog()">
                Cancel
            </button>
            <button class="btn-default btn-blue" (click)="configDialog()">
                Ok
            </button>
        </div>
    </div>
}
